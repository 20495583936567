import React from "react";

export default function BlogsImg() {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      data-name="Layer 1"
      width="628.95175"
      height="540.74875"
      viewBox="0 0 628.95175 540.74875"
    >
      <rect
        id="ae13a9af-8be6-48d2-be97-15b9bb35f653"
        data-name="Rectangle 246"
        x="0.32514"
        y="0.43193"
        width="532.26441"
        height="313.47738"
        fill="#e6e6e6"
      />
      <rect
        id="a5b6fe3b-52e9-48cc-a961-c4a66fbfd4e0"
        data-name="Rectangle 264"
        width="532.26441"
        height="32.39915"
        fill="#f50057"
      />
      <rect
        id="f9022efb-4ab6-4da5-b9f8-05744185cb65"
        data-name="Rectangle 247"
        x="12.2742"
        y="42.35071"
        width="507.51155"
        height="255.96132"
        fill="#fff"
      />
      <circle
        id="b8863a4f-d6d1-45f4-aeb2-54ce0fc41c58"
        data-name="Ellipse 194"
        cx="50.43802"
        cy="16.1168"
        r="5.35947"
        fill="#fff"
      />
      <circle
        id="bbe8bb57-e8a1-4ebb-9530-09dc8d102d8f"
        data-name="Ellipse 195"
        cx="70.78166"
        cy="16.1168"
        r="5.35947"
        fill="#fff"
      />
      <circle
        id="e8afff7b-3b6f-4dbf-9548-6e45ece64f65"
        data-name="Ellipse 246"
        cx="30.04157"
        cy="16.1168"
        r="5.35947"
        fill="#fff"
      />
      <rect
        id="b1f15dfa-2d28-41bb-b4d1-13ec40007797"
        data-name="Rectangle 250"
        x="71.46529"
        y="86.55195"
        width="389.12937"
        height="10.16355"
        fill="#e6e6e6"
      />
      <rect
        id="ad95e1cc-3eac-41f8-b758-723622ce1f63"
        data-name="Rectangle 251"
        x="196.65866"
        y="111.96048"
        width="138.74262"
        height="10.16355"
        fill="#e6e6e6"
      />
      <rect
        id="b780e72c-b22a-4ee2-b16a-5905a6a8a985"
        data-name="Rectangle 251"
        x="196.65866"
        y="163.50023"
        width="138.74262"
        height="10.16355"
        fill="#e6e6e6"
      />
      <rect
        id="a53233dc-737e-4f4d-ba21-0434f7dacc3a"
        data-name="Rectangle 251"
        x="196.65866"
        y="189.89863"
        width="138.74262"
        height="10.16355"
        fill="#e6e6e6"
      />
      <rect
        id="a2199747-3d5b-4c24-9aea-bee34d6022f4"
        data-name="Rectangle 252"
        x="127.82906"
        y="137.36902"
        width="276.40183"
        height="10.16355"
        fill="#e6e6e6"
      />
      <rect
        x="387.59466"
        y="236.06219"
        width="73"
        height="18.0486"
        fill="#f50057"
      />
      <polygon
        points="531.231 529.365 518.972 529.364 513.139 482.076 531.234 482.077 531.231 529.365"
        fill="#ffccaa"
      />
      <path
        d="M510.21452,525.861h23.64384a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H495.32765a0,0,0,0,1,0,0v0A14.88688,14.88688,0,0,1,510.21452,525.861Z"
        fill="#2f2e41"
      />
      <polygon
        points="577.231 529.365 564.972 529.364 559.139 482.076 577.234 482.077 577.231 529.365"
        fill="#ffccaa"
      />
      <path
        d="M556.21449,525.861h23.64387a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H541.32764a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,556.21449,525.861Z"
        fill="#2f2e41"
      />
      <path
        d="M861.25816,568.15162a10.74271,10.74271,0,0,1-2.06222-16.343l-8.0725-114.55784,23.253,2.25509.63867,112.18666a10.80091,10.80091,0,0,1-13.757,16.45913Z"
        transform="translate(-285.52412 -179.62562)"
        fill="#ffccaa"
      />
      <path
        d="M816.17638,685.16736,802.68,684.52381a4.499,4.499,0,0,1-4.28589-4.46289l-.9419-136.55665a4.50113,4.50113,0,0,1,5.14649-4.48535l53.99365,7.83789a4.47383,4.47383,0,0,1,3.85352,4.41993l6.94433,126.53418a4.50045,4.50045,0,0,1-4.5,4.53417h-14.55a4.47889,4.47889,0,0,1-4.44531-3.80078s-10.99023-94.91216-12.49927-94.8565c-1.51733.02832-10.53686,97.51959-10.53686,97.51959a4.51711,4.51711,0,0,1-4.46875,3.96582Q816.2838,685.17322,816.17638,685.16736Z"
        transform="translate(-285.52412 -179.62562)"
        fill="#2f2e41"
      />
      <path
        d="M856.38719,475.74376a4.48167,4.48167,0,0,1-1.85872-3.40065L852.82462,441.467a12.39863,12.39863,0,0,1,24.34643-3.92684l7.48456,27.60491a4.50508,4.50508,0,0,1-3.16562,5.52076l-21.29064,5.77257A4.4829,4.4829,0,0,1,856.38719,475.74376Z"
        transform="translate(-285.52412 -179.62562)"
        fill="#3f3d56"
      />
      <circle cx="550.97489" cy="198.17616" r="24.56103" fill="#ffccaa" />
      <path
        d="M747.49529,426.05641a10.52722,10.52722,0,0,1,.2393,1.64013l42.95745,24.782,10.44141-6.01093,11.13117,14.57227-22.33714,15.92056-49.00792-38.66268a10.4958,10.4958,0,1,1,6.57573-12.24133Z"
        transform="translate(-285.52412 -179.62562)"
        fill="#ffccaa"
      />
      <path
        d="M792.22631,450.3712a4.48171,4.48171,0,0,1,1.29315-3.65336l21.86341-21.86849a12.39862,12.39862,0,0,1,19.16808,15.51622l-15.57,23.9922a4.50508,4.50508,0,0,1-6.22448,1.32511l-18.5043-12.00853A4.48292,4.48292,0,0,1,792.22631,450.3712Z"
        transform="translate(-285.52412 -179.62562)"
        fill="#3f3d56"
      />
      <path
        d="M853.87961,397.23335c-4.582,4.88079-13.09132,2.26067-13.68835-4.40717a8.05467,8.05467,0,0,1,.01014-1.55569c.30826-2.95357,2.01461-5.635,1.60587-8.7536a4.59038,4.59038,0,0,0-.84011-2.14891c-3.65124-4.88934-12.22227,2.18686-15.6682-2.2393-2.113-2.714.3708-6.98713-1.25065-10.02051-2.14006-4.00358-8.47881-2.0286-12.45388-4.22115-4.42275-2.43949-4.15822-9.22525-1.24686-13.3527,3.55052-5.03359,9.77572-7.71951,15.92336-8.10661s12.25292,1.27475,17.99229,3.51145c6.52109,2.54134,12.98768,6.05351,17.00067,11.78753,4.88021,6.97317,5.34986,16.34793,2.90917,24.50175C862.68836,387.18848,857.62128,393.24767,853.87961,397.23335Z"
        transform="translate(-285.52412 -179.62562)"
        fill="#2f2e41"
      />
      <path
        d="M913.47588,720.18781h-193a1,1,0,0,1,0-2h193a1,1,0,0,1,0,2Z"
        transform="translate(-285.52412 -179.62562)"
        fill="#3f3d56"
      />
      <path
        d="M861.01455,418.76893q-.64014-.46-1.30517-.90332a32.36092,32.36092,0,0,0-7.59571-3.72418v-6.45362h-19v5.98767a33.31967,33.31967,0,0,0-24.16308,27.68622l-12.9375,96.05078a4.47759,4.47759,0,0,0,.93066,3.40137,4.41909,4.41909,0,0,0,3.05371,1.67285c4.48291.44727,13.78028,2.18457,25.67969,8.7959a38.12545,38.12545,0,0,0,18.70557,4.77441,45.08927,45.08927,0,0,0,15.22607-2.71093,4.46654,4.46654,0,0,0,2.918-4.00489c.46582-10.33691,3.19043-63.54394,11.124-95.999A33.17758,33.17758,0,0,0,861.01455,418.76893Z"
        transform="translate(-285.52412 -179.62562)"
        fill="#3f3d56"
      />
    </svg>
  );
}
