import React from "react";

export default function ExperienceImg() {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      data-name="Layer 1"
      width="779.31989"
      height="579.94408"
      viewBox="0 0 779.31989 579.94408"
    >
      <rect
        id="ab2e16f2-9798-47da-b25d-769524f3c86f"
        data-name="Rectangle 62"
        x="0.3"
        y="19.289"
        width="703.57599"
        height="333.85901"
        fill="#e6e6e6"
      />
      <rect
        id="bf81c03f-68cf-4889-8697-1102f95f97bb"
        data-name="Rectangle 75"
        x="20.41899"
        y="47.918"
        width="663.33899"
        height="278.54901"
        fill="#fff"
      />
      <rect
        id="f065dccc-d150-492a-a09f-a7f3f89523f0"
        data-name="Rectangle 80"
        width="703.57599"
        height="29.89"
        fill="#f50057"
      />
      <circle
        id="bdbbf39c-df25-4682-8b85-5a6af4a1bd14"
        data-name="Ellipse 90"
        cx="22.21198"
        cy="17.57598"
        r="5.54"
        fill="#fff"
      />
      <circle
        id="abcd4292-0b1f-4102-9b5e-e8bbd87baabc"
        data-name="Ellipse 91"
        cx="43.24"
        cy="17.57598"
        r="5.54"
        fill="#fff"
      />
      <circle
        id="a3fb731e-8b3d-41ca-96f2-91600dc0b434"
        data-name="Ellipse 92"
        cx="64.26899"
        cy="17.57598"
        r="5.54"
        fill="#fff"
      />
      <rect
        x="402.65457"
        y="90.26775"
        width="93.42307"
        height="7.0219"
        fill="#e6e6e6"
      />
      <rect
        x="402.65457"
        y="110.88168"
        width="144.26556"
        height="7.02188"
        fill="#f50057"
      />
      <rect
        x="404.15457"
        y="130.5137"
        width="117.57327"
        height="7.02188"
        fill="#e6e6e6"
      />
      <rect
        x="402.65457"
        y="152.05453"
        width="68.63736"
        height="7.02188"
        fill="#e6e6e6"
      />
      <rect
        x="402.65457"
        y="173.09537"
        width="103.59158"
        height="7.02188"
        fill="#f50057"
      />
      <rect
        x="360.07397"
        y="88.0589"
        width="11.43958"
        height="11.43956"
        fill="#e6e6e6"
      />
      <rect
        x="360.07397"
        y="108.67282"
        width="11.43958"
        height="11.43954"
        fill="#f50057"
      />
      <rect
        x="360.07397"
        y="128.30487"
        width="11.43958"
        height="11.43958"
        fill="#e6e6e6"
      />
      <rect
        x="360.07397"
        y="149.8457"
        width="11.43958"
        height="11.43958"
        fill="#e6e6e6"
      />
      <rect
        x="360.07397"
        y="170.88654"
        width="11.43958"
        height="11.43958"
        fill="#f50057"
      />
      <rect
        x="402.65457"
        y="194.26773"
        width="93.42307"
        height="7.02188"
        fill="#e6e6e6"
      />
      <rect
        x="402.65457"
        y="214.88168"
        width="144.26556"
        height="7.02188"
        fill="#f50057"
      />
      <rect
        x="404.15457"
        y="234.5137"
        width="117.57327"
        height="7.02188"
        fill="#e6e6e6"
      />
      <rect
        x="402.65457"
        y="256.05453"
        width="68.63736"
        height="7.02188"
        fill="#e6e6e6"
      />
      <rect
        x="402.65457"
        y="277.09537"
        width="103.59158"
        height="7.02188"
        fill="#e6e6e6"
      />
      <rect
        x="360.07397"
        y="192.0589"
        width="11.43958"
        height="11.43958"
        fill="#e6e6e6"
      />
      <rect
        x="360.07397"
        y="212.67282"
        width="11.43958"
        height="11.43954"
        fill="#f50057"
      />
      <rect
        x="360.07397"
        y="232.30487"
        width="11.43958"
        height="11.43958"
        fill="#e6e6e6"
      />
      <rect
        x="360.07397"
        y="253.8457"
        width="11.43958"
        height="11.43958"
        fill="#e6e6e6"
      />
      <rect
        x="360.07397"
        y="274.88654"
        width="11.43958"
        height="11.43958"
        fill="#e6e6e6"
      />
      <rect
        x="107.11233"
        y="249.34749"
        width="93.42307"
        height="7.02189"
        fill="#3f3d56"
      />
      <rect
        x="94.61233"
        y="267.59344"
        width="117.57327"
        height="7.02189"
        fill="#3f3d56"
      />
      <path
        d="M424.88435,320.79764a61.02248,61.02248,0,0,1-89.92457,53.72147l-.01185-.01186a61.00362,61.00362,0,1,1,89.93642-53.70961Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#3f3d56"
      />
      <circle cx="153.85516" cy="141.22599" r="16.58252" fill="#fff" />
      <path
        d="M392.50991,352.1a85.47057,85.47057,0,0,1-57.23926-.22169l-.01186-.00822,12.3539-24.38615h33.16505Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#fff"
      />
      <polygon
        points="656.072 567.394 643.813 567.394 637.98 520.106 656.075 520.107 656.072 567.394"
        fill="#ffb7b7"
      />
      <path
        d="M869.53913,739.30633l-39.53076-.00146v-.5a15.38728,15.38728,0,0,1,15.38647-15.38623h.001l24.144.001Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#2f2e41"
      />
      <polygon
        points="686.016 567.394 698.276 567.394 704.108 520.106 686.014 520.107 686.016 567.394"
        fill="#ffb7b7"
      />
      <path
        d="M893.22884,723.41961l24.144-.001h.001a15.3873,15.3873,0,0,1,15.38648,15.38623v.5l-39.53077.00146Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#2f2e41"
      />
      <circle
        cx="844.49784"
        cy="348.34723"
        r="21.42812"
        transform="translate(-53.56669 789.61575) rotate(-63.57977)"
        fill="#ffb7b7"
      />
      <path
        d="M828.719,495.75878s-6.76678,28.19488-3.38336,41.72845,13.53357,106.01282,13.53357,106.01282l6.76678,73.30676,27.06708,2.25562L869.31967,633.35l3.38336-74.43457,19.17255,72.179v85.96808l28.70605,1.12781.61664-111.90741s2.25562-87.96808-12.40576-99.246-16.91693-13.53354-16.91693-13.53354Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#2f2e41"
      />
      <path
        d="M869.57968,371.70122l25.67926,13.53354,3.38336,117.29077s-14.66138-9.02237-37.21728,2.25559-37.21729,3.38339-37.21729,3.38339l13.53357-50.75082-5.639-67.66776,8.06543-7.32047Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#cbcbcb"
      />
      <path
        d="M840.1705,380.2907l-4.68475,3.81628s-29.3227,14.66135-30.4505,24.81153l20.30035,55.262s5.639,12.40576,1.12781,16.91693-9.0224,15.78916-7.8946,21.42813-6.76678,29.3227,2.25562,29.3227,30.4505-54.13422,29.32269-75.56235S848.23868,378.73,840.1705,380.2907Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#2f2e41"
      />
      <path
        d="M859.88435,372.972l10.32985-1.71887s38.34509,10.15015,43.98407,27.06711l-15.78918,39.47284s11.278,72.17892,7.89459,72.17892h-3.38336s9.02233,6.76679,3.38336,9.02237-19.17255,20.30032-22.55591,12.40573-23.68372-101.50165-21.4281-121.802S857.88136,375.09878,859.88435,372.972Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#2f2e41"
      />
      <path
        d="M864.36734,327.36577l1.00363-2.02-5.05-2.50907s-5.571-9.06457-15.65184-6.51723-14.61641,4.07187-14.61641,4.07187l-5.03724,2.53457,2.52817,2.51545-4.53544,1.52458,3.03,1.50543-3.52547,2.02637,1.89784,10.38788s3.15217-7.87881,9.21214-4.86794,17.14458-1.55643,17.14458-1.55643l9.63008,18.63725s1.98669-6.53328,5.52884-4.79121C865.92624,348.30732,874.97551,333.89572,864.36734,327.36577Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#2f2e41"
      />
      <path
        d="M895.73611,525.2648a10.05573,10.05573,0,0,1,7.18746-13.6417l4.34113-35.47018,14.14564,12.03057-6.29109,31.83867a10.11027,10.11027,0,0,1-19.38314,5.24264Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#ffb7b7"
      />
      <path
        d="M835.234,522.824a10.05575,10.05575,0,0,0-8.59341-12.80269l-8.07766-34.80992L805.77268,488.674l9.63157,30.99212A10.11027,10.11027,0,0,0,835.234,522.824Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#ffb7b7"
      />
      <path
        d="M896.28134,389.12932s14.66131-5.639,19.17255,11.278S925.604,451.15816,925.604,451.15816c.28032,18.81388-.71968,18.81388,0,37.21726l-3.38336,16.91693-19.17255-2.25562.83623-43.06469-9.85863-43.77564Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#2f2e41"
      />
      <path
        d="M815.18539,402.15169l-6.95141.74356s-6.58216,4.89542-6.58216,16.17337-1.12781,45.11185-1.12781,45.11185-.63966.79157,4.51117,29.32269c2.51059,13.90645,6.84917,20.46888,6.84917,20.46888l14-13-1.67656-33.40821,4.51117-18.04474Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#2f2e41"
      />
      <path
        d="M988.65994,739.972h-224a1,1,0,0,1,0-2h224a1,1,0,0,1,0,2Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#cbcbcb"
      />
      <path
        d="M522.65994,739.972h-119a1,1,0,0,1,0-2h119a1,1,0,0,1,0,2Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#cbcbcb"
      />
      <path
        d="M469.74545,697.43517c-19.51084,14.54435-25.04043,40.13465-25.04043,40.13465s26.10369,2.00927,45.61453-12.53511S515.36,684.90006,515.36,684.90006,489.2563,682.89085,469.74545,697.43517Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#f2f2f2"
      />
      <path
        d="M477.8728,704.26376C468.49572,726.72,445.011,738.292,445.011,738.292s-8.28141-24.83665,1.09568-47.29285,32.86172-34.02823,32.86172-34.02823S487.24989,681.80755,477.8728,704.26376Z"
        transform="translate(-210.34006 -160.02796)"
        fill="#f2f2f2"
      />
    </svg>
  );
}
